import icon from "assets/images/icons/close-1-1.png";
import logo4 from "assets/images/bgza.png";
import logo3 from "assets/images/bgza.png";
import logo2 from "assets/images/bgza.png";
import logo1 from "assets/images/bgza.png";
import logo6 from "assets/images/update-01-10-2021/bgza.png";
import logo7 from "assets/images/update-01-10-2021/bgza.png";
import logo8 from "assets/images/update-01-10-2021/bgza.png";
import logo5 from "assets/images/update-09-06-2021/bgza.png";
import logo10 from "assets/images/update-09-06-2021/bgza.png";
import logo9 from "assets/images/update-26-02-2021/logo-horizontal-portfolio.png";

const home = {
  id: 1,
  name: "Home",
  href: "/"
};

const navItemsTwo = [
  { ...home, href: "#home" },
];

const navItems = [
  home,
  {
    id: 2,
    name: "Services",
    href: "/services",
    subNavItems: [
      { id: 1, name: "Tech Solutions", href: "/tech-solutions" },
      { id: 2, name: "Staff Solutions", href: "/staff-solutions", isNew: true },
      { id: 3, name: "Bounty Creative", href: "/bounty-creative" },
      { id: 4, name: "Project Solutions", href: "/project-solutions" },
      { id: 5, name: "Engineering Solutions", href: "/engineering-solutions" },
      { id: 6, name: "Energy Solutions", href: "/energy-solutions" },
      { id: 7, name: "General Supplies", href: "/general-supplies" },
    ],
  },
  {
    id: 3,
    name: "Contact",
    href: "/contact",
  },
];

export const socials = [
  {
    id: 1,
    icon: "fab fa-facebook-square",
    href: "#",
  },
  {
    id: 2,
    icon: "fab fa-twitter",
    href: "#",
  },
  {
    id: 3,
    icon: "fab fa-instagram",
    href: "#",
  },
];

const headerData = {
  title: "Bounty Group ZA",
  title2: "Welcome to Bounty Group ZA.",
  title3: "Welcome to Bounty Group ZA.",
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  navItems,
  navItemsTwo,
  address: "Suite 20 Golden Street USA",
  phone: "+27 83 4002 481",
  phone2: "+27 83 4002 481",
  email: "info@bountygroupza.com",
  icon,
  socials,
  text: "Bounty Group ZA.",
};

export default headerData;
