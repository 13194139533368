import { mainFooter } from "data/mainFooter";
import Link from "next/link";
import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import TextSplit from "../Reuseable/TextSplit";
import axios from 'axios';
import { newsletter } from "data/contactSection";
import Message from "components/MessagePopup/MessagePopup";

const {
  year,
  author,
  registration,
  logo,
  text,
  socials,
  links,
  address,
  phone,
  email,
  textBottom,
} = mainFooter;

const MainFooter = ({ normalPadding = true }) => {

  const handleSuccess = () => {
    setSucces(true);
    setTimeout(() => {
      setSucces(false);
    }, 3000);
  }
  const handleError = () => {
    setErrors(true);
    setTimeout(() => {
      setErrors(false);
    }, 3000);
  }

  const [email_n, setEmail] = useState("")
  const [textr, seText] = useState("");
  const [success, setSucces] = useState(false);
  const [errors, setErrors] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // console.log(formData.get("email"));
    let data = new FormData();
    formData.forEach((value, name) => (data.append(name, value)));

    axios
      .post(
        newsletter.endpoint,
        data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          "Access-Control-Allow-Origin": "*"
        }
      })
      .then(function (response) {
        if (response.status == 200) {
          handleSuccess();
          seText('Email Sent Successfully!');
          setEmail('');
        }
      })
      .catch(error => {
        handleError();
        seText('Something Went Wrong, Please Try Again');
        // console.log(error);
      });
  };


  return (
    <footer className={`main-footer${normalPadding ? " normal-padding" : ""}`}>
      <div className="auto-container">
        <div className="widgets-section">
          <Row className="clearfix">
            <Col xl={3} lg={6} md={6} sm={12} className="column">
              <div className="footer-widget logo-widget">
                <div className="widget-content">
                  <div className="logo">
                    <Link href="/">
                      <a>
                        <Image id="fLogo" src={logo.src} alt="" />
                      </a>
                    </Link>
                  </div>
                  <div className="text">{text}</div>
                  <ul className="social-links clearfix">
                    {socials.map(({ id, icon, href }) => (
                      <li key={id}>
                        <a href={href}>
                          <span className={icon}></span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} className="column">
              <div className="footer-widget links-widget">
                <div className="widget-content">
                  <h6>Explore</h6>
                  <Row className="clearfix">
                    <Col md={6} sm={12}>
                      <ul>
                        {links.slice(0, 5).map(({ id, href, title }) => (
                          <li key={id}>
                            <Link href={href}>{title}</Link>
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <Col md={6} sm={12}>
                      <ul>
                        {links.slice(5).map(({ id, href, title }) => (
                          <li key={id}>
                            <Link href={href}>{title}</Link>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} className="column">
              <div className="footer-widget info-widget">
                <div className="widget-content">
                  <h6>Contact</h6>
                  <ul className="contact-info">
                    <li className="address">
                      <span className="icon flaticon-pin-1"></span>{" "}
                      <TextSplit text={address} />
                    </li>
                    <li>
                      <span className="icon flaticon-call"></span>
                      <a href={`tel:${phone.split(" ").join("")}`}>{phone}</a>
                    </li>
                    <li>
                      <span className="icon flaticon-email-2"></span>
                      <a href={`mailto:${email}`}>{email}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6} sm={12} className="column">
              <div className="footer-widget newsletter-widget">
                <div className="widget-content">
                  <h6>Newsletter</h6>
                  <div className="newsletter-form">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group clearfix">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          required
                          onChange={ev => { setEmail(ev.target.value) }}
                          value={email_n}
                        />
                        {success && <Message variant='success'>{textr}</Message>}
                        {errors && <Message variant='danger'>{textr}</Message>}
                        <button type="submit" className="theme-btn">
                          <span className="fa fa-envelope"></span>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="text">{textBottom}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="inner clearfix">
            <div className="copyright">
              <a
                href="https://privacypolicies.com/live/e4f2bf64-6974-41ca-8afe-acd9f812c014"
                color="text.secondary"
                target='_blank'
                rel="noopener noreferrer"
              >
                {'Privacy Policy'}
              </a><br/>
              {registration} <br />&copy; Copyright {year} {author}
              <p style={{ fontSize: '2 !important' }}>Designed By <a style={{ color: '#FF5349', fontSize: '2 !important' }} target='_blank' href='https://nxgiz.com' rel="noreferrer">NXGIZ</a> </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainFooter;
