import demoBg from "assets/images/background/footer-bg-2.jpg";
import logo from "assets/images/bgza.png";
import logo3 from "assets/images/logo-3.png";
import logo6 from "assets/images/update-01-10-2021/logo-6-light.png";
import logo7 from "assets/images/update-01-10-2021/logo-7-light.png";
import logo8 from "assets/images/update-01-10-2021/logo-8-light.png";
import logo5 from "assets/images/update-09-06-2021/logo-5-light.png";

export const footer = { year: new Date().getFullYear(), author: "Bounty Group ZA", registration: "Bounty Trade and Entreprise | Reg. 2021/572258/07" };

export const mainFooterDemo = {
  ...footer,
  demoBg,
  title: "Bounty Group ZA!",
  tagline: "Bounty Group ZA",
  templateLink: "#",
};

export const mainFooter = {
  logo,
  logo3,
  logo5,
  logo6,
  logo7,
  logo8,
  bg: demoBg,
  title: "Let’s Start Working Together",
  about:
    "There are many variation of passages of lorem ipsum available, but the majority suffered.",
  about2: "The new corporate agency theme",
  about3:
    "We’ve grown our business on a set of \n three building blocks. Enjoy the \n people you work.",
  text: "Welcome to Bounty Group ZA, One Stop For All Your Solutions.",
  address: "South Africa",
  address3: "South Africa",
  address2: "South Africa",
  phone: "+27 83 4002 481",
  phone2: "+27 83 4002 481",
  email: "info@bountygroupza.com",
  textBottom:
    "Sign up for our latest news & articles. We won’t give you spam mails.",
  subscribeText:
    "Register and get notified about the news & updates before it gets too late.",
  socials: [
    {
      id: 1,
      href: "#",
      icon: "fab fa-facebook-square",
    },
    {
      id: 2,
      href: "#",
      icon: "fab fa-twitter",
    },
    {
      id: 3,
      href: "#",
      icon: "fab fa-instagram",
    }
  ],
  links: [
    {
      id: 1,
      href: "/",
      title: "Home",
    },
    {
      id: 3,
      href: "/services",
      title: "Services",
    },
    {
      id: 4,
      href: "#",
      title: "Latest News",
    },
    {
      id: 5,
      href: "/contact",
      title: "Contact",
    },
    {
      id: 6,
      href: "#",
      title: "Support",
    },
    {
      id: 7,
      href: "#",
      title: "Privacy Policy",
    },
    {
      id: 8,
      href: "#",
      title: "Terms of Use",
    },
    {
      id: 9,
      href: "#",
      title: "Help",
    },
  ],
  ...footer,
  newses: [
    {
      id: 1,
      image: "footer-1-1.png",
      date: "20 Nov, 2020",
      title: "The best digital services for the startups",
    },
    {
      id: 2,
      image: "footer-1-2.png",
      date: "20 Nov, 2020",
      title: "The best digital services for the startups",
    },
  ],
};
