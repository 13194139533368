import bg1 from "assets/images/icons/preloader.png";
import { StaticImageData } from "next/image";
import React from "react";

interface PreloaderType {
  bg?: StaticImageData;
  loading: any;
  className: string
}

const Preloader = ({ loading, bg = bg1, className = "" }:PreloaderType) => {
  return (
    <div
      style={{
        zIndex: loading ? 9999 : -1,
      }}
      className={`preloader animated${loading ? "" : " fadeOut"} ${className}`}
    >
      <div style={{ backgroundImage: `url(${bg.src})` }} className="icon"></div>
    </div>
  );
};

export default Preloader;
