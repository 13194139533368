import { StateCreator } from "zustand";
import { useActiveType } from "types/useActive";
import axios from 'axios';

// export interface ActivePage {
//     activePage: useActiveType
// }
export interface activePageSlice {
    active: string
    setActive: (id:string) => void
    loading: boolean
    error: string
}

export const createActivePageSlice: StateCreator<activePageSlice> = (set) => ({
    loading: false,
    error: null,
    active: '#home',
    setActive: async (id) => {
        set({ loading: true });
        try {
            set({ active: id, loading: false })
        } catch (error) {
            set({ error, loading: false });
        }
    },
})