import {create} from 'zustand'
import { activePageSlice, createActivePageSlice } from './slices/activePage'
import { galleriesSlice, createGalleriesSlice } from './slices/galleries'
import { navigationSlice, createNavigationSlice } from './slices/navigation'


type StoreState = activePageSlice & galleriesSlice & navigationSlice // & AnotherSlice

export const useAppStore = create<StoreState>()((...a) => ({
    ...createActivePageSlice(...a),
    ...createGalleriesSlice(...a),
    ...createNavigationSlice(...a),
    // ...createAnotherSlice(...a),
}))