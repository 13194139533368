import { StateCreator } from "zustand";
import { galleryType } from "types/galleries";

export interface galleriesSlice {
    techSolutions: galleryType
    bountyCreative: galleryType
    energySolutions: galleryType
    engineeringSolutions: galleryType
    generalSupplies: galleryType
    staffSolutions: galleryType
    projectSolutions: galleryType
    productNames: Array<string>
    partners: galleryType
    loading: boolean
    error: string
}

export const createGalleriesSlice: StateCreator<galleriesSlice> = () => ({
    loading: false,
    error: null,
    techSolutions: {
        title: 'tech-solution-',
        total: 43,
        path: '/static/galleries/techSolutions/'
    },
    bountyCreative: {
        title: 'bounty-creative-',
        total: 8,
        path: '/static/galleries/bountyCreative/'
    },
    energySolutions: {
        title: 'energy-solution-',
        total: 118,
        path: '/static/galleries/energySolutions/'
    },
    engineeringSolutions: {
        title: 'engineering-solution-',
        total: 8,
        path: '/static/galleries/engineeringSolutions/'
    },
    generalSupplies: {
        title: 'general-supplies-',
        total: 12,
        path: '/static/galleries/generalSupplies/'
    },
    staffSolutions: {
        title: 'staff-solutions-',
        total: 6,
        path: '/static/galleries/staffSolutions/'
    },
    projectSolutions: {
        title: 'project-solution-',
        total: 12,
        path: '/static/galleries/projectSolutions/'
    },
    partners: {
        title: 'sponsor-',
        total: 22,
        path: '/static/galleries/sponsors/'
    },
    productNames: [
        'VOIP',
        'Cartridges',
        'Network Products',
        'Web Camera',
        'Speakers/Headset',
        'Projector',
        'Printers/Fax/Scanners',
        'Biometric Scanners and readers',
        'Notebook/Laptop Bag',
        'LCD Monitors',
        'Keyboard/Mouse',
        'Flash Drive/SD Cards',
        'External HDD Enclosure',
        'Digital Camera/Video',
        'Cables & Connectors',
        'Bluetooth adapter',
        'Batteries & Chargers',
        'External TV box',
        'PCI Sound/TV/FM cards',
        'PCI Modem/3G Adapter',
        'DVD-RW',
        'Motherboards optical drives',
        'Memory Modules',
        'Hard Disk Drives',
        'Graphics Cards',
        'Intel Processors',
        'UPS',
        'NTP Clocks',
        'KVM Extenders',
        'Power supplies',
        'Computer cases',
        'Servers',
        'Branded computers',
        'Custom-made Computers',
        'Drones'
    ]
})