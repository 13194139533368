import { StateCreator } from "zustand";
import { navigationType } from "types/navigation";
import axios from 'axios';

// export interface ActivePage {
//     activePage: navigationType
// }
export interface navigationSlice {
    toggleMenu: () => void
    menuStatus: boolean
    toggleSearch: () => void
    openSearch: boolean;
    setCurrentActive: (value: string) => void
    currentActive: string;
    loading: boolean
    error: string
}

export const createNavigationSlice: StateCreator<navigationSlice> = (set) => ({
    loading: false,
    error: null,
    menuStatus: false,
    openSearch: false,
    currentActive: '#home',
    toggleSearch: async () => {
        set({ loading: true });
        try {
            set((openSearch) => ({
                ...openSearch, // Spread the current state to preserve other properties
                openSearch: !openSearch,
                loading: false
            }));

        } catch (error) {
            set({ error, loading: false });
        }
    },
    setCurrentActive: async (value) => {
        set({ loading: true });
        try {
            set({ currentActive: value, loading: false })
        } catch (error) {
            set({ error, loading: false });
        }
    },
    toggleMenu: async () => {
        set({ loading: true });
        try {
            set((menuStatus) => ({
                ...menuStatus, // Spread the current state to preserve other properties
                menuStatus: !menuStatus.menuStatus,
                loading: false
            }));
        } catch (error) {
            set({ error, loading: false });
        }
    },
})