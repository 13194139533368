export const contactSection = {
  inputs: [
    {
      name: "username",
      type: "text",
      placeholder: "Your Name",
      required: true,
    },
    {
      name: "email",
      type: "email",
      placeholder: "Email Address",
      required: true,
    },
    {
      name: "phone",
      type: "text",
      placeholder: "Phone Number",
      required: true,
    },
    {
      name: "subject",
      type: "text",
      placeholder: "Subject",
      required: true,
    },
    {
      name: "message",
      placeholder: "Write Message",
      required: true,
    },
  ],
  title: "Offices near you",
  contacts: [
    {
      id: 1,
      name: "Austin",
      address: "22 Texas West Hills",
      email: "needhelp@linoor.com",
      phone: "666 888 000",
    },
    {
      id: 2,
      name: "Boston",
      address: "5 Federal Street Boston",
      email: "needhelp@linoor.com",
      phone: "666 888 000",
    },
    {
      id: 3,
      name: "New york",
      address: "8th Broklyn New York",
      email: "needhelp@linoor.com",
      phone: "666 888 000",
    },
    {
      id: 4,
      name: "baltimore",
      address: "3 Lombabr 50 Baltimore",
      email: "needhelp@linoor.com",
      phone: "666 888 000",
    },
  ],
  contacts2: [
    {
      id: 1,
      icon: "fa fa-map-marker-alt",
      text: "South Africa",
    },
    {
      id: 2,
      icon: "fa fa-envelope",
      text: "info@bountygroupza.com",
      email: true,
    },
    {
      id: 3,
      icon: "fas fa-phone",
      icon1: "fa-thin fa-pipe",
      icon2: "fab fa-whatsapp",
      text: "+27 83 4002 481",
      phone: true,
    }
  ]
};

export const contactSectionTwo = {
  title: "Write us any message",
  text: "Our team  of expert is ready to answer any of your query.",
  socials: [
    "fab fa-twitter",
    "fab fa-facebook",
    "fab fa-instagram",
  ],
};


export const conractInformation = {
  httpmethod: "POST",
  endpoint: "https://bountygroupza.com/mailer/index.php/sendMail", 
}


export const newsletter = {
  httpmethod: "POST",
  endpoint: "https://bountygroupza.com/mailer/index.php/newsletter",
}
